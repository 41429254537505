@import "~antd/dist/antd.min.css";
@import "~react-grid-layout/css/styles.css";
@import "~react-resizable/css/styles.css";

#root {
  height: 100%;
}

body {
  margin: 0;
  padding: 0;
  font-family: "Source Sans Pro", sans-serif;
  background-color: #f0f2f5;
}

.annotation-list-popover .ant-popover-inner-content {
  padding: 12px 8px;
}

.ant-table-tbody > tr,
.ant-table-tbody > tr > td,
.ant-table-thead > tr {
  transition: none;
}
